import { useState, useEffect } from 'react';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Page } from '../Interfaces/Page';
import getMenu from '../Components/Fetch/getMenu';

export const useMenuContext = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const [menu, setMenu] = useState([] as Array<Page>);
    const [tier, setTier] = useState('');

    useEffect(() => {
        let isMounted = true; // Add this variable to track component mount state

        const fetchData = async () => {
            let response = await getMenu(instance);
            if (isMounted) {
                setMenu(response.menu);
                setTier(response.tier.toLowerCase());
            }
        };

        if (isAuthenticated) {
            fetchData();
        }

        // Cleanup function called when component is unmounted
        return () => {
            isMounted = false;
        };
    }, [isAuthenticated, instance]);

    return { menu, tier };
}