import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import postError from './postError';

const postSignature = async (instance: IPublicClientApplication, releaseNumber: string, imageString: string) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch('api/release/sign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify({
                Image: imageString,
                ReleaseNumber: releaseNumber
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }
        
        return response;
    } catch (error) {
        postError(instance, "Error in postSignature: " + error);
    }

    return null;
};

export default postSignature;