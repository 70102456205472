import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import postError from './postError';

const getRelease = async (instance: IPublicClientApplication, releaseNumber: string) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch(`api/release/${releaseNumber}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        const data = await response;

        if (data.status === 200) {
            return data.json()
        }
    } catch (error) {
        postError(instance, "Error in getRelease: " + error);
    }

    return "";
};

export default getRelease;