import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Customer } from '../../Interfaces/Customer';
import postError from './postError';


const getCustomerList = async (instance: IPublicClientApplication, customerSearchString: string) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch(`api/customer/search/${customerSearchString}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        const data = await response.json();

        if (data?.customers?.length > 0) {
            return data.customers;
        }
    } catch (error) {
        postError(instance, "Error in getCustomerList: " + error);
    }

    return [] as Array<Customer>;
};

export default getCustomerList;