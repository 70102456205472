import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./AuthConfig";
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
const rootElement = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const msalInstance = new PublicClientApplication(msalConfig);

rootElement.render(
    <MsalProvider instance={msalInstance}>
        <IonApp>
            <IonReactRouter basename={baseUrl}> 
                <App  />
            </IonReactRouter>
        </IonApp>
    </MsalProvider>
 );

registerServiceWorker();

