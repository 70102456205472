import React from 'react';
import { IonGrid, IonCol, IonRow, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { useMenuContext } from '../../Contexts/menuContext'
import { useHistory } from 'react-router-dom';

const Home = () => {
    const { menu } = useMenuContext()
    const history = useHistory();

    return (
        <IonGrid>
            <IonRow>
                {menu.map((page, index) => <IonCol key={`customer-${index}`} size="6">
                    <IonCard id={`home-${index}`} onClick={() => { history.push(`/${page.name}`); }}>
                        <IonCardHeader>
                            <IonCardTitle>{page.displayName}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {page.description}
                        </IonCardContent>
                    </IonCard>
                </IonCol>)}
            </IonRow>
        </IonGrid>
    );
};

export default Home;
