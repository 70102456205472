import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CreateQuoteRequest } from '../../Interfaces/Quote';
import postError from './postError';

const postQuote = async (instance: IPublicClientApplication, createQuote: CreateQuoteRequest) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch('api/quote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify(createQuote)
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        let data = await response.json();

        return data;
    } catch (error) {
        postError(instance, "Error in postQuote: " + error);
    }

    return null;
};

export default postQuote;