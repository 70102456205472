import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useLocation, useHistory } from 'react-router-dom';
import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonHeader,
    IonToolbar
} from '@ionic/react';
import Header from '../Header';
import { useMenuContext } from '../../Contexts/menuContext'

import './menu.css';

const Menu = () => {
    const { instance } = useMsal();
    const location = useLocation();
    const history = useHistory();

    const { menu } = useMenuContext()

    function signIn() {
        instance.loginRedirect();
    }

    function signOut() {
        instance.logout({
            onRedirectNavigate: (url) => {
                // Return false to skip server side (microsoft) logout 
                return true;
            }
        });
    }

    //TODO: this will need to be built out better to handle special chars this is just a placeholder for now
    function getTitleCase(str: string) {
        return str.substr(0, 1).toUpperCase() + str.substr(1).replace('-', ' ')
    }

    return (
        <>
            <Header title={getTitleCase(history.location.pathname.substr(1))} />
            <IonMenu contentId="main" type="overlay" side="end">
                <IonContent>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonTitle>Menu</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonList id="menu-list">
                        <AuthenticatedTemplate>
                            <IonMenuToggle key={0} autoHide={false}>
                                <IonItem key='home' className={location.pathname === '/' ? 'selected' : ''} onClick={() => { history.push('/') }}>
                                    <IonLabel>Home</IonLabel>
                                </IonItem>
                                {menu.map(page => <IonItem key={page.name} className={location.pathname === `/${page.name}` ? 'selected' : ''} onClick={() => { history.push(`/${page.name}`); }}>
                                    <IonLabel>{page.displayName}</IonLabel>
                                </IonItem>)}
                                <IonItem key='SignOut' onClick={() => { signOut(); history.push('/') }}>
                                    <IonLabel>Sign Out</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <IonItem onClick={() => { signIn(); history.push('/') }}>
                                <IonLabel>Sign In</IonLabel>
                            </IonItem>
                        </UnauthenticatedTemplate>
                    </IonList>
                </IonContent>
            </IonMenu>
        </>
    );
};

export default Menu;
