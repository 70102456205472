import React from 'react';
import { IonHeader, IonToolbar, IonImg, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';

interface headerProps {
    title: string
}

const Header = ({ title }: headerProps) => (
    <IonHeader className="header-main">
        <IonToolbar color="primary" >
            <IonImg src={process.env.PUBLIC_URL + '/images/MINILOGO.png'} className="navLogo" slot="start"></IonImg>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
                <IonMenuButton />
            </IonButtons>
        </IonToolbar>
    </IonHeader>
);

export default Header;