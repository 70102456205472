import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Page } from '../../Interfaces/Page';
import postError from './postError';

const getMenu = async (instance: IPublicClientApplication) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        let response = await fetch(`api/menu`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        let data = await response.json();

        let tier = data?.tier;
        let menu = [] as Array<Page>;

        if (data && data.pages) {
            menu = data.pages;
        }

        return { menu, tier }
    } catch (error) {
        postError(instance, "Error in getMenu: " + error);
    }
    
    return {
        menu: [] as Array<Page>,
        tier: "Test"
    }
};

export default getMenu;