import React, { useState, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import SignatureCanvas from 'react-signature-canvas';
import { IonLoading, IonButton, IonItem, IonInput, IonLabel, IonCard, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import getRelease from '../../Components/Fetch/getRelease';
import postSignature from '../../Components/Fetch/postSignature';

import { Release } from '../../Interfaces/Release';
import { Product } from '../../Interfaces/Product';
import postDeliveryImage from '../../Components/Fetch/postDeliveryImage';

const Delivery = () => {
    const [releaseInput, setReleaseInput] = useState("");
    const [signatureImage] = useState("");
    const sigPad = useRef<SignatureCanvas>({} as SignatureCanvas);
    const [base64Doc, setBase64Doc] = useState<string | null>("");
    const inputBtn = useRef<HTMLInputElement>({} as HTMLInputElement);
    const [releases, setReleases] = useState([] as Array<Release>);
    const [showLoading, setShowLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const { instance } = useMsal();

    let clear = () => {
        sigPad.current.clear();
    }

    let photoButtonClick = () => {
        inputBtn.current.click();
    }

    let clearPage = () => {
        setReleases([]);
        setReleaseInput("");
        clear();
    }

    let isCanvasBlank = () => {
        let canvas = sigPad.current.getTrimmedCanvas();
        let context = canvas.getContext('2d');
        let pixelBuffer = new Uint32Array(
            context ? context.getImageData(0,0,canvas.width,canvas.height).data.buffer : new Uint32Array()
        );
        return !pixelBuffer.some(color => color !== 0);
    }

    let handleReleaseLookup = () => {
        if (!showLoading) { //prevents this from running multiple times (clickling button and blur trigger same time)
            if (releaseInput !== "") {
                setShowLoading(true);
                getRelease(instance, releaseInput).then(data => {
                    if (data !== null && data.headerInformation?.releaseNumber !== undefined) {
                        if ((releases.length > 0 && data.headerInformation.customerNumber === releases[0].headerInformation.customerNumber) || releases.length === 0) {
                            if (releases.filter((release) => release.headerInformation.releaseNumber === data?.headerInformation?.releaseNumber).length === 0) {
                                let tmpReleases = releases
                                tmpReleases.push(data);
                                setReleases(tmpReleases);
                            } else {
                                setMessage("This release number has already been added");
                                setShowMessage(true);
                            }
                        } else {
                            setMessage("This release number does not belong to this company");
                            setShowMessage(true);
                        }
                    } else {
                        setMessage("Release Number Not Found");
                        setShowMessage(true);
                    }
                    setShowLoading(false);
                    setReleaseInput("");
                })
            }
        }
    }

    let buildProductCard = (index: number, product: Product) => (
        <IonCard id={`product-${index}`} key={`product-${index}`}>
            <IonGrid>
                <IonRow>
                    <IonCol size="9">
                        {product.productNumber !== null && <div><b>{product.productNumber}</b></div>}
                        {product.productDescription1 !== null && <div>{product.productDescription1}</div>}
                        {product.productDescription2 !== null && <div>{product.productDescription2}</div>}
                    </IonCol>
                    {product.quantityShipped !== null && <IonCol className='alignRight' size="3">
                        {product.quantityShipped?.toString() === "0"
                            ? <span><strong>Not Shipped</strong></span>
                            : <span><strong>Qty: </strong>{product.quantityShipped}</span>
                        }
                    </IonCol>}
                </IonRow>
            </IonGrid>
        </IonCard>
    )


    return (
        <div>
            <IonLoading isOpen={showLoading} />
            <IonLoading isOpen={showMessage} spinner={null} message={message} backdropDismiss onDidDismiss={() => { setShowMessage(false); setMessage(""); }} />

            <div className='form-field'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="9">
                            <IonItem>
                                <IonInput value={releaseInput} placeholder="Release Number" onIonInput={e => {
                                    setReleaseInput(e.detail.value!)
                                }} clearInput></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol size="3">
                            <IonButton expand="block" onClick={handleReleaseLookup}>
                                <IonIcon icon={add} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>

            {releases.map((release, relIndex) => <div id={`release-${relIndex}`} key={`release-${relIndex}`}>
                {relIndex === 0 && <div className='form-field'>
                    <div>
                        {release.headerInformation.customerDescription !== null && <div><b>{release.headerInformation.customerDescription}</b></div>}
                        {release.headerInformation.address !== null && <div>{release.headerInformation.address}</div>}
                        {release.headerInformation.city !== undefined
                            && release.headerInformation.state !== undefined
                            && release.headerInformation.zip !== undefined
                            && <div>{`${release.headerInformation.city}, ${release.headerInformation.state} ${release.headerInformation.zip}`}</div>}
                        <span>{release.headerInformation.phone}</span><br></br>
                    </div>
                </div>}
                {release.lineItemDetails.lineItemDetails.length > 0 && <div className='form-field'>
                    <IonLabel>{release.headerInformation.releaseNumber} Products</IonLabel>
                    { /* Returns products that have shipped */}
                    {release.lineItemDetails.lineItemDetails.filter(p => p.quantityShipped?.toString() !== "0").map((product, index) => {
                        return buildProductCard(index, product);
                    })}
                    { /* Returns products that have NOT shipped */}
                    {release.lineItemDetails.lineItemDetails.filter(p => p.quantityShipped?.toString() === "0").map((product, index) => {
                        return buildProductCard(index, product);
                    })}
                </div>}
            </div>)}

            {releases.length > 0 && <div className='form-field'>
                <IonLabel>Proof of Delivery</IonLabel>
                <IonGrid>
                    {base64Doc && base64Doc.length > 0 && <IonRow>
                        <IonCol>
                            <img src={base64Doc} alt="" />
                        </IonCol>
                    </IonRow>}
                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" onClick={photoButtonClick}>
                                Take Photo <input type="file" name="image" accept="image/*" capture="environment" hidden ref={inputBtn} onChange={e => {
                                    if (e.target.files) {
                                        if (e.target.files[0].size <= 26214400) {
                                            //setFileErrorLabel("")
                                            let reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setBase64Doc(reader.result ? reader.result?.toString() : null);
                                            }, false);
  
                                            reader.readAsDataURL(e.target.files[0]);
                                        } else {
                                            //setFileErrorLabel("File cannot exceed 25MB!")
                                        }
                                    }
                                }}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonLabel>{signatureImage ? "Signed" : "Sign"}</IonLabel>
                {signatureImage
                    ? <div className='sigFinal'><img src={signatureImage} alt="signature" /></div>
                    : <>
                        <SignatureCanvas penColor='black' canvasProps={{ height: 200, className: 'sigCanvas' }} ref={sigPad} />
                        <IonGrid>
                            <IonRow>
                                <IonCol><IonButton color="medium" expand="block" onClick={clear}>Clear Sig</IonButton></IonCol>
                                <IonCol><IonButton color="medium" expand="block" onClick={clearPage}>Clear Form</IonButton></IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                }
                <IonGrid>
                    <IonRow>
                        <IonCol><IonButton expand="block" onClick={() => {
                            let canvasNotBlank = !isCanvasBlank();
                            if (canvasNotBlank || base64Doc) {
                                setShowLoading(true);
                                let promiseArray: any[] = [];
                                releases.forEach((release) => {
                                    if (canvasNotBlank) {
                                        promiseArray.push(postSignature(instance, release.headerInformation.releaseNumber, sigPad.current.getTrimmedCanvas().toDataURL('image/png')));
                                    }
                                    if (base64Doc) {
                                        promiseArray.push(postDeliveryImage(instance, release.headerInformation.releaseNumber, base64Doc));
                                    }
                                })
                                Promise.allSettled(promiseArray).then((response) => {
                                    let errors = response
                                        .filter((x): x is PromiseFulfilledResult<number> => x.status === "fulfilled" && x.value === null);

                                    if (errors.length > 0) {
                                        let errMsg = "There was an error uploading. Please check internet connection and try again.";

                                        setShowLoading(false);
                                        setMessage(errMsg);
                                        setShowMessage(true);
                                    } else {
                                        setReleases([]);
                                        setShowLoading(false);
                                        setMessage("Upload Sucessful");
                                        setShowMessage(true);
                                    }
                                });
                            } else {
                                setMessage("You must provide proof of delivery or signature.");
                                setShowMessage(true);
                            }
                        }}>Save</IonButton></IonCol>
                    </IonRow>
                </IonGrid>
            </div>}
        </div>
    );
};

export default Delivery;