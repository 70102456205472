import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Warehouse } from '../../Interfaces/Warehouse';
import postError from './postError';

const getWarehouse = async (instance: IPublicClientApplication) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch(`api/order/warehouses`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        const data = await response.json();

        if (data?.length > 0) {
            return data
        } 
    } catch (error) {
        postError(instance, "Error in getWarehouse: " + error);
    }

    return [] as Array<Warehouse>;
}

export default getWarehouse;