import React from 'react';
import { IonButton, IonCol, IonGrid, IonLabel, IonRow} from '@ionic/react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
   const history = useHistory();
   return (<div>
        <IonGrid>
            <IonRow>
                <IonCol size="12" className="ion-text-justify">
                       <IonLabel style={{ display: "flex", justifyContent: "center", textAlign: "center"}}>Oops! Looks like this page is missing a few nuts and bolts!</IonLabel>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="4"></IonCol>
                <IonCol size="4">
                   <IonButton color="primary" expand='block' onClick={() => history.push('/')}>Home</IonButton>
                </IonCol>
               <IonCol size="4"></IonCol>
           </IonRow>
        </IonGrid>
    </div>)
};

export default NotFound;