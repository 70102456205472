import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CreateOrderRequest } from '../../Interfaces/Order';
import postError from './postError';

const postOrder = async (instance: IPublicClientApplication, createOrder: CreateOrderRequest) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch('api/order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify(createOrder)
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        let data = await response.json();

        return data;
    } catch (error) {
        postError(instance, "Error in postOrder: " + error);
    }

    return null;
};

export default postOrder;