import { requestParams } from '../../AuthConfig';
import { IPublicClientApplication } from '@azure/msal-browser';

const postError = async (instance: IPublicClientApplication, errorMessage: string) => {
    try {
        let token = await instance.acquireTokenSilent({
            scopes: requestParams.scopes,
            account: instance.getAllAccounts()[0]
        });

        const response = await fetch('api/Utility/errorLogger', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify({
                message: errorMessage
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP Status ${response.status}`);
        }

        return response;
    } catch (error) {
        window.alert("You are experiencing connectivity issues. Please check your internet connection and try again./n/rError: " + error);
    }

    return null;
};

export default postError;