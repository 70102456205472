import React from 'react';
import { useMsal } from "@azure/msal-react";
import './splash.css';
import { IonButton } from '@ionic/react';

const Splash = () => {
    const { instance } = useMsal();

    function signIn() {
        instance.loginRedirect();
    }

    return (
        <div className="splashScreen">
            <img className="splashLogo" src={process.env.PUBLIC_URL + '/images/MINILOGO.png'} alt="Alma Bolt Logo" />
            <h1>Alma Bolt Company</h1>
            <IonButton className="splashButton" color='light' expand='block' onClick={signIn}><b>Log In</b></IonButton>
        </div>
    );
};

export default Splash;
