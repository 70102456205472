import React from 'react';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { Route, Redirect, Switch } from 'react-router-dom';
import { IonRouterOutlet, IonContent, IonPage, setupIonicReact } from '@ionic/react';
import { useMenuContext } from './Contexts/menuContext'
import Menu from './Components/Menu';
import Splash from './Components/Splash';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './variables.css';
//main css
import './app.css';

//pages
import Home from './Pages/Home';
import Order from './Pages/Order';
import Quote from './Pages/Quote';
import Delivery from './Pages/Delivery';
import Search from './Pages/Search';
import NotFound from './Pages/NotFound';

setupIonicReact({
    mode: 'md',
    innerHTMLTemplatesEnabled: true
});

const App = () => {
    const { menu, tier } = useMenuContext();
 
    return (
        <IonPage className={tier}>
            <AuthenticatedTemplate>
                <Menu />
                <IonRouterOutlet id="main">
                    <IonContent id="main-content">
                        <div className='container'>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route path='/Order' component={menu.find((m) => m.name === "Order") ? Order : NotFound} />
                                <Route path='/Quote' component={menu.find((m) => m.name === "Quote") ? Quote : NotFound} />
                                <Route path='/Delivery' component={menu.find((m) => m.name === "Delivery") ? Delivery : NotFound} /> 
                                <Route path='/Search' component={menu.find((m) => m.name === "Search") ? Search : NotFound} />
                                <Route path='/NotFound' component={NotFound} />
                                <Redirect from='*' to='/NotFound' />
                            </Switch>
                        </div>
                    </IonContent>
                </IonRouterOutlet>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Splash />
            </UnauthenticatedTemplate>
        </IonPage>
    );
};

export default App;
